exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-management-team-js": () => import("./../../../src/pages/about/management-team.js" /* webpackChunkName: "component---src-pages-about-management-team-js" */),
  "component---src-pages-about-technology-partners-js": () => import("./../../../src/pages/about/technology-partners.js" /* webpackChunkName: "component---src-pages-about-technology-partners-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-disclosure-js": () => import("./../../../src/pages/cookies-disclosure.js" /* webpackChunkName: "component---src-pages-cookies-disclosure-js" */),
  "component---src-pages-digital-transformation-application-development-js": () => import("./../../../src/pages/digital-transformation/application-development.js" /* webpackChunkName: "component---src-pages-digital-transformation-application-development-js" */),
  "component---src-pages-digital-transformation-enterprise-integration-services-js": () => import("./../../../src/pages/digital-transformation/enterprise-integration-services.js" /* webpackChunkName: "component---src-pages-digital-transformation-enterprise-integration-services-js" */),
  "component---src-pages-digital-transformation-index-js": () => import("./../../../src/pages/digital-transformation/index.js" /* webpackChunkName: "component---src-pages-digital-transformation-index-js" */),
  "component---src-pages-digital-transformation-salesforce-js": () => import("./../../../src/pages/digital-transformation/salesforce.js" /* webpackChunkName: "component---src-pages-digital-transformation-salesforce-js" */),
  "component---src-pages-digital-workplace-index-js": () => import("./../../../src/pages/digital-workplace/index.js" /* webpackChunkName: "component---src-pages-digital-workplace-index-js" */),
  "component---src-pages-digital-workplace-intelligent-service-desk-js": () => import("./../../../src/pages/digital-workplace/intelligent-service-desk.js" /* webpackChunkName: "component---src-pages-digital-workplace-intelligent-service-desk-js" */),
  "component---src-pages-digital-workplace-modern-device-management-js": () => import("./../../../src/pages/digital-workplace/modern-device-management.js" /* webpackChunkName: "component---src-pages-digital-workplace-modern-device-management-js" */),
  "component---src-pages-digital-workplace-workplace-as-a-service-js": () => import("./../../../src/pages/digital-workplace/workplace-as-a-service.js" /* webpackChunkName: "component---src-pages-digital-workplace-workplace-as-a-service-js" */),
  "component---src-pages-directory-3-ps-getlisted-js": () => import("./../../../src/pages/directory/3ps/getlisted.js" /* webpackChunkName: "component---src-pages-directory-3-ps-getlisted-js" */),
  "component---src-pages-fsb-js": () => import("./../../../src/pages/fsb.js" /* webpackChunkName: "component---src-pages-fsb-js" */),
  "component---src-pages-global-project-management-js": () => import("./../../../src/pages/global-project-management.js" /* webpackChunkName: "component---src-pages-global-project-management-js" */),
  "component---src-pages-global-work-management-platform-solutions-js": () => import("./../../../src/pages/global-work-management-platform-solutions.js" /* webpackChunkName: "component---src-pages-global-work-management-platform-solutions-js" */),
  "component---src-pages-global-workforce-contingent-staffing-js": () => import("./../../../src/pages/global-workforce/contingent-staffing.js" /* webpackChunkName: "component---src-pages-global-workforce-contingent-staffing-js" */),
  "component---src-pages-global-workforce-diversity-recruiting-js": () => import("./../../../src/pages/global-workforce/diversity-recruiting.js" /* webpackChunkName: "component---src-pages-global-workforce-diversity-recruiting-js" */),
  "component---src-pages-global-workforce-employer-of-record-js": () => import("./../../../src/pages/global-workforce/employer-of-record.js" /* webpackChunkName: "component---src-pages-global-workforce-employer-of-record-js" */),
  "component---src-pages-global-workforce-index-js": () => import("./../../../src/pages/global-workforce/index.js" /* webpackChunkName: "component---src-pages-global-workforce-index-js" */),
  "component---src-pages-global-workforce-msp-staffing-services-js": () => import("./../../../src/pages/global-workforce/msp-staffing-services.js" /* webpackChunkName: "component---src-pages-global-workforce-msp-staffing-services-js" */),
  "component---src-pages-global-workforce-recruiter-on-demand-js": () => import("./../../../src/pages/global-workforce/recruiter-on-demand.js" /* webpackChunkName: "component---src-pages-global-workforce-recruiter-on-demand-js" */),
  "component---src-pages-global-workforce-rpo-services-js": () => import("./../../../src/pages/global-workforce/rpo-services.js" /* webpackChunkName: "component---src-pages-global-workforce-rpo-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infrastructure-cloud-js": () => import("./../../../src/pages/infrastructure/cloud.js" /* webpackChunkName: "component---src-pages-infrastructure-cloud-js" */),
  "component---src-pages-infrastructure-connectivity-js": () => import("./../../../src/pages/infrastructure/connectivity.js" /* webpackChunkName: "component---src-pages-infrastructure-connectivity-js" */),
  "component---src-pages-infrastructure-data-center-js": () => import("./../../../src/pages/infrastructure/data-center.js" /* webpackChunkName: "component---src-pages-infrastructure-data-center-js" */),
  "component---src-pages-infrastructure-edge-js": () => import("./../../../src/pages/infrastructure/edge.js" /* webpackChunkName: "component---src-pages-infrastructure-edge-js" */),
  "component---src-pages-infrastructure-index-js": () => import("./../../../src/pages/infrastructure/index.js" /* webpackChunkName: "component---src-pages-infrastructure-index-js" */),
  "component---src-pages-infrastructure-network-security-operations-js": () => import("./../../../src/pages/infrastructure/network-security-operations.js" /* webpackChunkName: "component---src-pages-infrastructure-network-security-operations-js" */),
  "component---src-pages-infrastructure-on-premises-js": () => import("./../../../src/pages/infrastructure/on-premises.js" /* webpackChunkName: "component---src-pages-infrastructure-on-premises-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-global-inclusion-diversity-js": () => import("./../../../src/pages/service-global-inclusion-diversity.js" /* webpackChunkName: "component---src-pages-service-global-inclusion-diversity-js" */),
  "component---src-pages-snippet-library-js": () => import("./../../../src/pages/snippet-library.js" /* webpackChunkName: "component---src-pages-snippet-library-js" */),
  "component---src-pages-solutions-smart-hands-js": () => import("./../../../src/pages/solutions/smart-hands.js" /* webpackChunkName: "component---src-pages-solutions-smart-hands-js" */),
  "component---src-pages-solutions-talent-marketplace-js": () => import("./../../../src/pages/solutions/talent-marketplace.js" /* webpackChunkName: "component---src-pages-solutions-talent-marketplace-js" */),
  "component---src-pages-vmsapp-js": () => import("./../../../src/pages/vmsapp.js" /* webpackChunkName: "component---src-pages-vmsapp-js" */),
  "component---src-pages-wms-js": () => import("./../../../src/pages/wms.js" /* webpackChunkName: "component---src-pages-wms-js" */),
  "component---src-pages-work-management-index-js": () => import("./../../../src/pages/work-management/index.js" /* webpackChunkName: "component---src-pages-work-management-index-js" */)
}

